.App {
  text-align: center;
}

.white-color {
  color: #FFFFFF !important;
}

.main-color {
  color: #161d30 !important;
}

.main-color-background {
  background-color: #161d30 !important;
}

.secondary-color {
  color: #d3d3d3 !important;
}

.secondary-color-background {
  background-color: #d3d3d3 !important;
}

.secondary-color-background:hover {
  background-color: #c3c3c3 !important;
}

.third-color-background {
  background-color: #0d3a5c !important;
}

.ui.primary.button {
  background-color: #0d3a5c !important;
}

.ui.primary.button:hover {
  background-color: #161d30 !important;
}

.third-color-background:hover {
  background-color: #161d30 !important;
}

.fourth-color-background {
  background-color: #172b48 !important;
}

.custom-loader {
  padding: 20px !important;
}

.inline {
  display: inline;
}

#back-button {
  background-color: rgba(255, 0, 0, 0);
  z-index: 9;
}

.ui.steps .step.active {
  background: #d3d3d3 !important;
}

.ui.steps .step.active .title {
  color: #0d3a5c !important;
}

.ui.steps .step.active:after {
  background-color: #d3d3d3 !important;
}

.ui.blue.progress .bar {
  background-color: #0d3a5c !important;
}

.ui.progress .bar {
  background: #d3d3d3 !important;
}

.trasparent-button {
  box-shadow: 0 0 0 0 !important;
}

.div-right {
  text-align: right;
}

.big-font {
  font-size: large !important;
}

.custom {
  width: 100% !important;
  top: 0px !important;
}

.input-red .input input[type=text]{
  background-color: red !important;
  color: white !important;
  font-weight: bold !important;
}

.no-padding-left{
  padding-left: 0px !important;
}

.no-padding-right{
  padding-right: 0px !important;
}

.divider-no-margin{
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.custom .ui.dimmer {
  padding: 0em !important;
}

.modals.dimmer .ui.scrolling.modal {
  margin: 0px !important;
}

.custom .header{
  padding: 0rem !important;
}

.ui.dimmer {
  height: 101% !important;
}

.image_operator_green{
  margin: 1px !important;
  border: 2px solid rgba(0,0,0,0.1) !important;
  border-color: #21ba45 !important;
}

.image_operator_red{
  margin: 1px !important;
  border: 2px solid rgba(0,0,0,0.1) !important;
  border-color: #db2828 !important;
}

.blink_me {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {  
  50% { opacity: 0; }
}